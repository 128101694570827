export interface YoutubePlayer {
  loadVideoByUrl: (videoId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function getYoutubeVideoId(videoUrl: string) {
  let ID = '';
  const url = videoUrl?.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (!url) return;
  if (url[2] === undefined) {
    [ID] = url;
  } else {
    [ID] = url[2].split(/[^\w-]/i);
  }
  return ID;
}

export function getYoutubeThumbnailById(videoId?: string) {
  return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
}
