import { Chevron } from '@klokgroep/shared-components/src/Icons';
import { ChevronButtonInPentagon } from '../ChevronButtonInPentagon';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MaxWidth } from '../MaxWidth';
import { RichText } from '../RichText';
import { ImageWithOptionalTypeAndKey, SiteIdType, VideoWithOptionalTypeAndKey } from '@klokgroep/sanity';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { VideoOrAutoplayVideo } from '@klokgroep/shared-components/src/Video';
import cx from 'classnames';
import styles from './BlockWithCarousel.module.css';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';

type ItemType = (ImageWithOptionalTypeAndKey | VideoWithOptionalTypeAndKey) | null;

export interface BlockWithCarouselProperties {
  autoPlay?: boolean;
  items?: ItemType[];
  maxWidthSize?: 'small' | 'medium' | 'extraLarge' | 'fullWidth';
  siteId?: SiteIdType;
  size?: 'small' | 'medium' | 'extraLarge';
  sizes?: { width: number; minWidth: number; ratio?: number }[];
  withoutContainer?: boolean;
  resizeToContainer?: boolean;
  captionRightTop?: boolean;
  noMarginOverflow?: boolean;
}

export const BLOCK_WITH_CAROUSEL_SLIDE_SIZES = [
  { width: 1900, minWidth: 1800, ratio: 16 / 9 },
  { width: 1800, minWidth: 1700, ratio: 16 / 9 },
  { width: 1700, minWidth: 1600, ratio: 16 / 9 },
  { width: 1600, minWidth: 1500, ratio: 16 / 9 },
  { width: 1500, minWidth: 1400, ratio: 16 / 9 },
  { width: 1400, minWidth: 1300, ratio: 16 / 9 },
  { width: 1300, minWidth: 1200, ratio: 16 / 9 },
  { width: 1200, minWidth: 1100, ratio: 16 / 9 },
  { width: 1100, minWidth: 1000, ratio: 16 / 9 },
  { width: 1000, minWidth: 900, ratio: 16 / 9 },
  { width: 900, minWidth: 800, ratio: 16 / 9 },
  { width: 800, minWidth: 768, ratio: 16 / 9 },
  { width: 768, minWidth: 768, ratio: 16 / 9 },
  { width: 700, minWidth: 600, ratio: 1 },
  { width: 600, minWidth: 500, ratio: 1 },
  { width: 500, minWidth: 400, ratio: 1 },
  { width: 400, minWidth: 300, ratio: 1 },
  { width: 300, minWidth: 1, ratio: 1 },
];

export const BlockWithCarousel = ({
  autoPlay,
  items = [],
  size = 'extraLarge',
  maxWidthSize,
  withoutContainer = false,
  sizes = BLOCK_WITH_CAROUSEL_SLIDE_SIZES,
  resizeToContainer = false,
  captionRightTop = false,
  noMarginOverflow,
}: BlockWithCarouselProperties) => {
  const { theme } = useSiteInfo();

  const [activeIndex, setActiveIndex] = useState(0);
  const interval = useRef<NodeJS.Timer>();
  const carousel = useRef(null);
  const nextButton = useRef(null);
  const previousButton = useRef(null);

  const onNext = useCallback(
    () =>
      setActiveIndex((currentIndex) => {
        clearInterval(interval.current);
        const newIndex = currentIndex + 1;
        return newIndex > (items || []).length - 1 ? 0 : newIndex;
      }),
    [items]
  );

  const onPrevious = useCallback(
    () =>
      setActiveIndex((currentIndex) => {
        clearInterval(interval.current);
        const newIndex = currentIndex - 1;
        return newIndex < 0 ? (items || []).length - 1 : newIndex;
      }),
    [items]
  );

  useEffect(() => {
    if (!autoPlay) {
      return;
    }

    interval.current = setInterval(() => {
      onNext();
    }, 5000);

    return () => clearInterval(interval.current);
  }, [autoPlay, onNext]);

  useEffect(() => {
    if (!carousel || !carousel.current) return;
    if (!previousButton || !previousButton.current || !nextButton || !nextButton.current) return;

    const previousButtonElement = previousButton.current as HTMLElement;
    const nextButtonElement = nextButton.current as HTMLElement;
    const carouselElement = carousel.current as HTMLElement;

    let firstTouch: number;

    const onTouchStart = (event: TouchEvent) => {
      event.preventDefault();
      firstTouch = event.touches[0].clientX;
    };

    const onTouchEnd = (event: TouchEvent) => {
      const currentTouch = event.changedTouches[0].clientX;
      if (firstTouch < currentTouch - 50) {
        onPrevious();
      } else if (firstTouch > currentTouch + 50) {
        onNext();
      }
    };

    const onMouseDown = (event: MouseEvent) => {
      firstTouch = event.clientX;
    };

    const onMouseUp = (event: MouseEvent) => {
      const currentTouch = event.clientX;
      if (firstTouch < currentTouch - 50) {
        onPrevious();
      } else if (firstTouch > currentTouch + 50) {
        onNext();
      }
    };

    carouselElement.addEventListener('touchstart', onTouchStart);
    carouselElement.addEventListener('touchend', onTouchEnd);
    carouselElement.addEventListener('mousedown', onMouseDown);
    carouselElement.addEventListener('mouseup', onMouseUp);
    previousButtonElement.addEventListener('touchend', onPrevious);
    nextButtonElement.addEventListener('touchend', onNext);

    return () => {
      carouselElement?.removeEventListener('touchstart', onTouchStart);
      carouselElement?.removeEventListener('touchend', onTouchEnd);
      carouselElement?.removeEventListener('mousedown', onMouseDown);
      carouselElement?.removeEventListener('mouseup', onMouseUp);
      previousButtonElement?.removeEventListener('touchend', onPrevious);
      nextButtonElement?.removeEventListener('touchend', onNext);
    };
  }, [carousel, onNext, onPrevious]);

  const style = useMemo(() => ({ transform: `translateX(-${activeIndex}00%)` }), [activeIndex]);

  return (
    <Container noMarginOverflow={noMarginOverflow} size={size} noMobilePadding withoutContainer={withoutContainer}>
      <div className={styles.container} ref={carousel}>
        <div className={cx(styles.slidesContainerContainer, { [styles.fullSize]: maxWidthSize === 'fullWidth' })}>
          <div className={styles.slidesContainer} style={style}>
            {items?.map((item, index) => (
              <Slide
                isActive={index === activeIndex}
                key={index}
                sizes={sizes}
                fullWidth={maxWidthSize === 'fullWidth'}
                item={item}
                resizeToContainer={resizeToContainer}
                captionRightTop={captionRightTop}
              />
            ))}
          </div>
        </div>
        <div className={cx(styles.buttonsContainer, { [styles.isFullHeight]: resizeToContainer })}>
          {theme === 'holding' ? (
            <Fragment>
              <ChevronButtonInPentagon ref={previousButton} pointLeft onClick={onPrevious} />
              <ChevronButtonInPentagon ref={nextButton} onClick={onNext} />
            </Fragment>
          ) : (
            <Fragment>
              <button className={styles.button} onClick={onPrevious} type="button" ref={previousButton}>
                <Chevron />
              </button>
              <button className={styles.button} onClick={onNext} type="button" ref={nextButton}>
                <Chevron />
              </button>
            </Fragment>
          )}
        </div>
        <div className={styles.indicatorsContainer}>
          <Indicators activeIndex={activeIndex} amount={(items || []).length} />
        </div>
      </div>
    </Container>
  );
};

const Container = ({
  children,
  size,
  noMobilePadding,
  withoutContainer,
  noMarginOverflow,
}: {
  children: ReactNode;
  size: BlockWithCarouselProperties['size'];
  noMobilePadding: boolean;
  withoutContainer: boolean;
  noMarginOverflow?: boolean;
}) =>
  withoutContainer ? (
    <div>{children}</div>
  ) : (
    <MaxWidth size={size} noMobilePadding={noMobilePadding} noMarginOverflow={noMarginOverflow}>
      {children}
    </MaxWidth>
  );

const Slide = ({
  isActive,
  item,
  sizes,
  resizeToContainer = false,
  fullWidth = false,
  captionRightTop,
}: {
  isActive?: boolean;
  item: ItemType;
  sizes: BlockWithCarouselProperties['sizes'];
  resizeToContainer?: boolean;
  fullWidth?: boolean;
  captionRightTop: boolean;
}) => {
  const showSlideContent = useMemo(
    () => !!item?.title || (!!item?.caption && captionRightTop),
    [captionRightTop, item?.caption, item?.title]
  );
  return (
    <div className={styles.slide}>
      {item?._type === 'image' ? (
        <SourceSetSanityImage asset={item as ImageWithOptionalTypeAndKey} sizes={sizes} />
      ) : item?._type === 'video' ? (
        <VideoOrAutoplayVideo
          fullWidth={fullWidth}
          isActive={isActive}
          noBorderRadius
          resizeToContainer={resizeToContainer}
          item={item as VideoWithOptionalTypeAndKey}
        />
      ) : undefined}
      {showSlideContent ? (
        <div className={styles.slideContent}>
          <div className={styles.overlay} />
          <div className={styles.slideContentContainer}>
            <RichText>
              {!!item?.title && <TitleOverTwoRules as="h2">{item?.title}</TitleOverTwoRules>}
              {!!item?.caption && (
                <figcaption className={cx(styles.caption, { [styles.captionRightTop]: captionRightTop })}>
                  {item?.caption}
                </figcaption>
              )}
            </RichText>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

const Indicators = ({ activeIndex, amount }: { activeIndex: number; amount: number }) => (
  <div className={styles.indicators}>
    {Array.from({ length: amount }).map((_, index) => (
      <div key={index} className={cx(styles.indicator, { [styles.isActive]: activeIndex === index })} />
    ))}
  </div>
);
