/* eslint-disable jsx-a11y/media-has-caption */
import { Fragment, MouseEvent, TouchEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getYoutubeThumbnailById, getYoutubeVideoId, YoutubePlayer } from '@klokgroep/shared-components/utils/youtube';
import { SourceSet } from '@klokgroep/shared-components/src/SourceSet';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { VideoPlay, VideoPlayRound } from '@klokgroep/shared-components/src/Icons';
import { XMarkIcon, SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import Script from 'next/script';
import styles from './Video.module.css';
import { BasicRectangleButton } from '@klokgroep/shared-components/src/Button/BasicRectangleButton';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';

declare global {
  interface Window {
    // TODO: find out how to type this
    YT: any;
  }
}

interface VideoOrAutoplayVideoProperties {
  isActive?: boolean;
  item: Omit<Extract<NonNullable<Sanity.Schema.MediaBlock['items']>[number], { _type: 'video' }>, '_type' | '_key'> & {
    _type?: 'video';
    _key?: string;
  };
  noBorderRadius?: boolean;
  resizeToContainer?: boolean;
  fullWidth?: boolean;
}

export const VideoOrAutoplayVideo = ({
  isActive,
  item,
  noBorderRadius,
  fullWidth,
  resizeToContainer,
}: VideoOrAutoplayVideoProperties) => {
  if (!!item && item?.autoPlay && !!item?.vimeoVideoUrl) {
    return (
      <AutoplayVideo
        resizeToContainer={resizeToContainer}
        src={item.vimeoVideoUrl}
        displaySoundControlButton={item.displaySoundControlButton}
      />
    );
  }

  if (!!item && !item?.autoPlay && !!item?.youtubeVideoUrl) {
    return (
      <Video
        fullWidth={fullWidth}
        isActive={isActive}
        noBorderRadius={noBorderRadius}
        videoUrl={item?.youtubeVideoUrl}
        identifier="title"
      />
    );
  }

  return null;
};

interface Properties {
  isActive?: boolean;
  identifier: string;
  noBorderRadius?: boolean;
  videoUrl: string;
  fullWidth?: boolean;
}

export const Video = ({ isActive, identifier, videoUrl, noBorderRadius, fullWidth }: Properties) => {
  const { theme } = useSiteInfo();

  const youtubeVideoId = useMemo(() => getYoutubeVideoId(videoUrl), [videoUrl]);
  const youtubeVideoImage = useMemo(() => getYoutubeThumbnailById(youtubeVideoId), [youtubeVideoId]);

  const previousIsActive = useRef<boolean>();

  const [modalOpen, setModalOpen] = useState(false);
  const [player, setPlayer] = useState<YoutubePlayer>();

  const onPlayClick = useCallback(
    (event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (player !== undefined) {
        setModalOpen(true);
        return;
      }

      setModalOpen(true);
    },
    [player]
  );

  const onCloseClick = useCallback((event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPlayer(undefined);
    setModalOpen(false);
  }, []);

  useEffect(() => {
    const onVideoReady = (event: { target: YoutubePlayer }) => {
      setPlayer(event.target);
    };

    if (modalOpen) {
      new window.YT.Player(identifier, {
        videoId: youtubeVideoId,
        enablejsapi: 1,
        playerVars: {
          modestbranding: 1,
          rel: 0,
          ecver: 2,
          showinfo: 0,
        },
        events: {
          onReady: onVideoReady,
        },
      });
    }
  }, [identifier, modalOpen, youtubeVideoId]);

  useEffect(() => {
    if (!player) return;
    player.playVideo();
  }, [player]);

  useEffect(() => {
    if (previousIsActive.current !== isActive) {
      if (!isActive) {
        player?.pauseVideo();
      }

      previousIsActive.current = isActive;
    }
  }, [isActive, player]);

  return (
    <Fragment>
      <Script src="https://www.youtube.com/iframe_api" async />
      <div className={cx(styles.modalContainer, { [styles.noBorderRadius]: noBorderRadius })}>
        <button type="button" className={styles.videoContainer} onClick={onPlayClick} onTouchStart={onPlayClick}>
          <div className={styles.videoThumbnail}>
            <SourceSet src={youtubeVideoImage} />
          </div>
          <div
            className={cx(styles.playButton, {
              [styles.rounded]: theme !== 'holding',
              [styles.smallButton]: fullWidth,
            })}>
            {theme === 'holding' || !theme ? <VideoPlay /> : <VideoPlayRound />}
          </div>
        </button>
        <div className={cx(styles.modal, { [styles.modalOpen]: modalOpen })}>
          {modalOpen ? (
            <div className={styles.modalContent}>
              <button type="button" onClick={onCloseClick} onTouchStart={onCloseClick} className={styles.closeButton}>
                <XMarkIcon />
              </button>
              <div id={identifier} className={styles.videoPlayer} />
            </div>
          ) : undefined}
        </div>
      </div>
    </Fragment>
  );
};

export const AutoplayVideo = ({
  src,
  videoInHexagon,
  resizeToContainer = false,
  displaySoundControlButton = false,
}: {
  src: string;
  videoInHexagon?: boolean;
  resizeToContainer?: boolean;
  displaySoundControlButton?: boolean;
}) => {
  const t = useCommonTranslations();
  const [muted, setMuted] = useState(true);
  const [videoHasLoaded, setVideoHasLoaded] = useState(false);
  const videoReference = useRef<HTMLVideoElement>(null);

  //Pause the video when scrolling out of view
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVideoHasLoaded(entry.isIntersecting);
      entry.isIntersecting ? videoReference.current?.play() : videoReference.current?.pause();
    });

    if (videoReference.current) {
      observer.observe(videoReference.current);
    }

    return () => {
      if (videoReference.current) {
        observer.unobserve(videoReference.current);
      }
    };
  }, [videoReference]);

  const handleToggleMute = () => setMuted((current) => !current);
  const IconForMuteButton: any = useMemo(() => (muted ? SpeakerWaveIcon : SpeakerXMarkIcon), [muted]);
  return (
    <div className={styles.autoplayContainer}>
      {displaySoundControlButton ? (
        <div className={styles.soundToggleWrapper}>
          <BasicRectangleButton
            type="button"
            theme="transparentWhite"
            onClick={handleToggleMute}
            Icon={IconForMuteButton}>
            {muted ? t('video_sound_on') : t('video_sound_off')}
          </BasicRectangleButton>
        </div>
      ) : undefined}

      <video
        className={cx(styles.autoplayVideo, {
          [styles.videoInHexagon]: videoInHexagon,
          [styles.resizeToContainer]: resizeToContainer,
        })}
        ref={videoReference}
        id="hero-video"
        autoPlay
        muted={muted}
        playsInline
        loop
        poster="">
        {videoHasLoaded ? <source src={videoHasLoaded ? src : undefined} type="video/mp4" /> : undefined}
      </video>
    </div>
  );
};
