import { PageHeroProperties, PageHeroRatio, SharedPageHeroProperties } from './PageHeroInterfaces';
import { useSiteInfo } from '../SiteInfoProvider';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';

const ssr = true;

const DynamicCorporateBoxedPageHero = dynamic<SharedPageHeroProperties>(
  () => import('./Corporate.Boxed.PageHero').then((module) => module.PageHero),
  { ssr }
);

const DynamicCorporateFullWidthPageHero = dynamic<SharedPageHeroProperties>(
  () => import('./Corporate.FullWidth.PageHero').then((module) => module.PageHero),
  { ssr }
);

const DynamicHoldingPageHero = dynamic<SharedPageHeroProperties>(
  () => import('./Holding.PageHero').then((module) => module.PageHero),
  { ssr }
);

export const moduleAddTitle = (title: string) => (
  <RichText>
    <TitleOverTwoRules as="h1">{title}</TitleOverTwoRules>
  </RichText>
);

export const getHeroSizes = (ratio: PageHeroRatio) => [
  { width: 1900, minWidth: 1800, ratio: ratio.desktop },
  { width: 1600, minWidth: 1500, ratio: ratio.desktop },
  { width: 1300, minWidth: 1200, ratio: ratio.desktop },
  { width: 1200, minWidth: 1100, ratio: ratio.desktop },
  { width: 1100, minWidth: 1000, ratio: ratio.desktop },
  { width: 1000, minWidth: 900, ratio: ratio.desktop },
  { width: 900, minWidth: 800, ratio: ratio.desktop },
  { width: 800, minWidth: 768, ratio: ratio.desktop },
  { width: 768, minWidth: 768, ratio: ratio.desktop },
  { width: 700, minWidth: 600, ratio: ratio.desktop },
  { width: 600, minWidth: 520, ratio: ratio.desktop },
  { width: 520, minWidth: 400, ratio: ratio.mobile },
  { width: 400, minWidth: 300, ratio: ratio.mobile },
  { width: 300, minWidth: 1, ratio: ratio.mobile },
];

export const PageHero = ({ forceBoxedHero, ...rest }: PageHeroProperties) => {
  const { theme } = useSiteInfo();

  const ComponentToRender = useMemo(
    () =>
      forceBoxedHero || theme === 'novaform' || theme === 'vandeklok' || theme === 'vanerk'
        ? DynamicCorporateBoxedPageHero
        : theme === 'heilijgers'
        ? DynamicCorporateFullWidthPageHero
        : (DynamicHoldingPageHero as React.ElementType),
    [theme, forceBoxedHero]
  );
  return <ComponentToRender {...rest} />;
};

export * from './HeroCarousel';
