import { BasicRectangleButtonProperties, HoldingButtonProperties } from './ButtonInterfaces';
import cx from 'classnames';
import React from 'react';
import styles from './Button.module.css';

export const BasicRectangleButton = ({
  children,
  reverse = false,
  theme,
  Icon,
  disabled,
  type,
  ...rest
}: BasicRectangleButtonProperties) => (
  <button
    disabled={disabled}
    className={getClassNames(theme, reverse)}
    type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
    {...rest}>
    <span>{children}</span>
    {Icon ? <Icon /> : undefined}
  </button>
);

const getClassNames = (theme: HoldingButtonProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.basicRectangle, {
    [styles.outline]: theme === 'outline',
    [styles.transparent]: theme === 'transparent',
    [styles.transparentWhite]: theme === 'transparentWhite',
    [styles.reverse]: reverse,
  });
