import React, { useMemo } from 'react';
import { VideoOrAutoplayVideo } from '@klokgroep/shared-components/src/Video';
import { BlockWithCarousel } from '@klokgroep/shared-components/src/BlockWithCarousel';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';

const SLIDE_SIZES = [
  { width: 1300, minWidth: 1200, ratio: 16 / 7 },
  { width: 1200, minWidth: 1100, ratio: 16 / 7 },
  { width: 1100, minWidth: 1000, ratio: 16 / 7 },
  { width: 1000, minWidth: 900, ratio: 16 / 7 },
  { width: 900, minWidth: 800, ratio: 16 / 7 },
  { width: 800, minWidth: 768, ratio: 16 / 7 },
  { width: 768, minWidth: 768, ratio: 16 / 7 },
  { width: 700, minWidth: 600, ratio: 16 / 7 },
  { width: 600, minWidth: 520, ratio: 16 / 7 },
  { width: 520, minWidth: 400, ratio: 1 },
  { width: 400, minWidth: 300, ratio: 1 },
  { width: 300, minWidth: 1, ratio: 1 },
];

interface Properties {
  items?: Array<any>;
  sizes?: { width: number; minWidth: number; ratio?: number }[];
  carouselAutoplay?: boolean;
}

export const HeroCarousel = ({ items = [], sizes = SLIDE_SIZES, carouselAutoplay = false }: Properties) => {
  const imageElement = useMemo(
    () =>
      (items || [])[0] ? <SourceSetSanityImage asset={(items || [])[0]} loading="eager" sizes={sizes} /> : undefined,
    [items, sizes]
  );

  return (
    <React.Fragment>
      {items && !!items[0] ? (
        items?.length > 1 ? (
          <BlockWithCarousel
            withoutContainer
            resizeToContainer
            items={items}
            sizes={sizes}
            autoPlay={carouselAutoplay}
          />
        ) : items[0]._type === 'video' ? (
          <VideoOrAutoplayVideo noBorderRadius resizeToContainer item={items[0]} />
        ) : (
          imageElement
        )
      ) : undefined}
      {}
    </React.Fragment>
  );
};
