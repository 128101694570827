import { ExternalLinkIcon } from '@klokgroep/shared-components/src/Icons';
import { getSiteIcon } from '@klokgroep/shared-components/src/Logos';
import { SiteIdType } from '@klokgroep/sanity';
import { useMemo } from 'react';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import styles from './SiteLabelPill.module.css';

interface Properties {
  isExternal?: boolean;
  siteId: SiteIdType;
}

export const SiteLabelPill = ({ siteId, isExternal }: Properties) => {
  const { siteId: currentSiteId } = useSiteInfo();
  const SiteIcon = getSiteIcon(siteId);

  const showSiteLabel = useMemo(() => siteId !== currentSiteId, [currentSiteId, siteId]);

  if (!showSiteLabel) return null;

  return (
    <div className={styles.siteLabelPill}>
      <SiteIcon />
      {isExternal ? <ExternalLinkIcon /> : undefined}
    </div>
  );
};
